

import { defineComponent } from 'vue';
import EventDetail from '../components/EventDetail.vue';

export default defineComponent({
  components: {
    EventDetail
  },
  setup() {
    console.log('create');
  }
});
